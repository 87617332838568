import App from '@/App.vue'
import '@/quasar'
import VueGoogleSignin from '@ht-lib/vue-google-signin/lib'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { auth } from './boot/firebase'
import router from './router'

Vue.use(Vuelidate)
Vue.use(new VueGoogleSignin(), {
  router,
  appName: 'Payment',
  firebaseAuth: auth,
  defaultRouteName: 'default'
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
