
import { Component } from 'vue-property-decorator'
import Vue from '../../VueBase'

@Component({
  name: 'Default'
})
export default class extends Vue {
  get name (): string {
    return this.$auth.user?.name ?? ''
  }

  get picUrl (): string {
    return this.$auth.user?.picture ?? '../assets/sad.svg'
  }
}
